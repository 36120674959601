// 引入页面的样式
import "../css/index.scss";

import { Curtains, Plane, Vec2 } from "curtainsjs";
import { gsap } from "gsap";

// 导入所有图片资源
import image01 from "../imgs/01.jpg";
import image02 from "../imgs/02.jpg";
import image03 from "../imgs/03.jpg";
import image04 from "../imgs/04.jpg";
import image05 from "../imgs/05.jpg";
import image06 from "../imgs/06.jpg";
import image07 from "../imgs/07.jpg";
import image08 from "../imgs/08.jpg";

// 包含所有图片URL的数组
const imageUrls = [image01, image02, image03, image04, image05, image06, image07, image08];

// 初始化加载计数和总图片数
let loadedCount = 0;
const totalCount = imageUrls.length;
const loadedProgress = { value: 0 };
const loadedImgs = [];

// 获取需要操作的元素
const loadingEl = document.querySelector("#loading");
const loadingProgressEl = loadingEl.querySelector("#loading .progress");
const loadingMask = document.querySelector("#loading .mask");
const m2LogoEl = document.querySelector(".m2-logo");

/* 获取loading元素的实际宽度，并设置css属性，提供给子元素计算等比例缩放 */
const getLoadingElWidth = () => {
    loadingEl.style.setProperty("--width", loadingEl.clientWidth);
};
getLoadingElWidth();
window.onresize = () => getLoadingElWidth();

// 记录鼠标位置以便发送给着色器
const mousePosition = new Vec2();
// 我们将保存上一次位置以计算移动强度/变化量
const mouseLastPosition = new Vec2();

const deltas = {
    max: 0,
    applied: 0,
};

const webgl = document.querySelector("#webgl");

const curtains = new Curtains({
    container: webgl,
    watchScroll: false,
    pixelRatio: Math.min(1.5, window.devicePixelRatio), // 限制像素比以提高性能
});

// 处理上下文丢失的情况
curtains.onContextLost(() => curtains.restoreContext());

const planeEl = document.querySelector("#plane");

const vs = `
precision mediump float;

// default mandatory variables
attribute vec3 aVertexPosition;
attribute vec2 aTextureCoord;

uniform mat4 uMVMatrix;
uniform mat4 uPMatrix;

// our texture matrix uniform
uniform mat4 simplePlaneTextureMatrix;

// custom variables
varying vec3 vVertexPosition;
varying vec2 vTextureCoord;

uniform float uTime;
uniform vec2 uResolution;
uniform vec2 uMousePosition;
uniform float uMouseMoveStrength;


void main() {

    vec3 vertexPosition = aVertexPosition;

    // get the distance between our vertex and the mouse position
    float distanceFromMouse = distance(uMousePosition, vec2(vertexPosition.x, vertexPosition.y));

    // calculate our wave effect
    float waveSinusoid = cos(5.0 * (distanceFromMouse - (uTime / 75.0)));

    // attenuate the effect based on mouse distance
    float distanceStrength = (0.4 / (distanceFromMouse + 0.4));

    // calculate our distortion effect
    float distortionEffect = distanceStrength * waveSinusoid * uMouseMoveStrength;

    // apply it to our vertex position
    vertexPosition.z +=  distortionEffect / 30.0;
    vertexPosition.x +=  (distortionEffect / 30.0 * (uResolution.x / uResolution.y) * (uMousePosition.x - vertexPosition.x));
    vertexPosition.y +=  distortionEffect / 30.0 * (uMousePosition.y - vertexPosition.y);

    gl_Position = uPMatrix * uMVMatrix * vec4(vertexPosition, 1.0);

    // varyings
    vTextureCoord = (simplePlaneTextureMatrix * vec4(aTextureCoord, 0.0, 1.0)).xy;
    vVertexPosition = vertexPosition;
}
`;

const fs = `
precision mediump float;

varying vec3 vVertexPosition;
varying vec2 vTextureCoord;

uniform sampler2D simplePlaneTexture;

void main() {
    // apply our texture
    vec4 finalColor = texture2D(simplePlaneTexture, vTextureCoord);

    // fake shadows based on vertex position along Z axis
    finalColor.rgb -= clamp(-vVertexPosition.z, 0.0, 1.0);
    // fake lights based on vertex position along Z axis
    finalColor.rgb += clamp(vVertexPosition.z, 0.0, 1.0);

    // handling premultiplied alpha (useful if we were using a png with transparency)
    finalColor = vec4(finalColor.rgb * finalColor.a, finalColor.a);

    gl_FragColor = finalColor;
}
`;

/**
 * handleMovement
 * @param {Event} e
 * @param {Plane} plane
 */
const handleMovement = (e, plane) => {
    // 更新鼠标的最后位置
    mouseLastPosition.copy(mousePosition);

    const mouse = new Vec2();

    // 触摸事件
    if (e.targetTouches) {
        mouse.set(e.targetTouches[0].clientX, e.targetTouches[0].clientY);
    }
    // 鼠标事件
    else {
        mouse.set(e.clientX, e.clientY);
    }

    // 稍微调整鼠标位置以平滑整体效果
    mousePosition.set(
        curtains.lerp(mousePosition.x, mouse.x, 0.3),
        curtains.lerp(mousePosition.y, mouse.y, 0.3)
    );

    // 将鼠标/触摸位置转换为相对于平面顶点的坐标，并更新uniform变量
    plane.uniforms.mousePosition.value = plane.mouseToPlaneCoords(mousePosition);

    //计算鼠标移动强度
    if (mouseLastPosition.x && mouseLastPosition.y) {
        let delta =
            Math.sqrt(
                Math.pow(mousePosition.x - mouseLastPosition.x, 2) +
                    Math.pow(mousePosition.y - mouseLastPosition.y, 2)
            ) / 2;
        delta = Math.min(maxMouseMoveStrength, delta);

        // 只有当移动变化量增加时才更新最大变化量
        if (delta >= deltas.max) {
            deltas.max = delta;
        }
    }
};

const plane = new Plane(curtains, planeEl, {
    vertexShader: vs,
    fragmentShader: fs,
    widthSegments: 20,
    heightSegments: 20,
    uniforms: {
        resolution: {
            // 平面的分辨率
            name: "uResolution",
            type: "2f", // 注意这是一个长度为 2 的浮点数数组
            value: [planeEl.clientWidth, planeEl.clientHeight],
        },
        time: {
            name: "uTime",
            type: "1f",
            value: 0,
        },
        mousePosition: {
            // 鼠标位置
            name: "uMousePosition",
            type: "2f",
            value: mousePosition,
        },
        mouseMoveStrength: {
            // 鼠标移动强度
            name: "uMouseMoveStrength",
            type: "1f",
            value: 0,
        },
    },
});
/** 最小鼠标移动强度 */
const minMouseMoveStrength = 0.6;
/** 最大鼠标移动强度 */
const maxMouseMoveStrength = 3;

plane
    .onReady(() => {
        // 初始焦点为左上角
        plane.uniforms.mousePosition.value = new Vec2(
            -1 * (window.innerWidth / 2),
            window.innerHeight / 2
        );

        // 监听交互事件
        webgl.addEventListener("mousemove", (e) => {
            handleMovement(e, plane);
        });
        webgl.addEventListener(
            "touchmove",
            (e) => {
                handleMovement(e, plane);
            },
            {
                passive: true,
            }
        );
    })
    .onRender(() => {
        plane.uniforms.time.value++;

        // 限制一个最小值，保证一直是动的
        if (deltas.max > minMouseMoveStrength) {
            // 通过阻尼减少两个增量：如果用户不移动鼠标，效果就会消失
            deltas.applied += (deltas.max - deltas.applied) * 0.02;
            deltas.max += (0 - deltas.max) * 0.01;

            // 发送新的鼠标移动强度值
            plane.uniforms.mouseMoveStrength.value = deltas.applied;
        }
    })
    .onAfterResize(() => {
        const planeBoundingRect = plane.getBoundingRect();
        plane.uniforms.resolution.value = [planeBoundingRect.width, planeBoundingRect.height];
    });

class Swiper {
    #canvas;
    #ctx;

    constructor() {
        // 获取设备像素比，解决移动端canvas绘制结果模糊
        const dpr = window.devicePixelRatio || 1;

        const canvas = document.createElement("canvas");

        // 设置canvas的实际大小
        canvas.width = Math.floor(planeEl.clientWidth * dpr);
        canvas.height = Math.floor(planeEl.clientHeight * dpr);

        this.#canvas = canvas;
        this.#ctx = this.#canvas.getContext("2d");

        // 缩放context以适应高DPI屏幕
        // this.#ctx.scale(dpr, dpr);

        // 把canvas作为纹理加载到平面
        plane.loadCanvas(this.#canvas, { sampler: "simplePlaneTexture" });

        // 画出第一张图
        this.#drawCurrentImage(loadedImgs[0]);
    }

    /**
     * 绘制图片到canvas中
     * @param {HTMLImageElement} img
     */
    #drawCurrentImage(img) {
        // 清除上次绘制的图片（可选）
        // ctx.clearRect(0, 0, canvas.width / dpr, canvas.height / dpr);

        // 计算绘制坐标和尺寸
        const centerX = this.#canvas.width / 2;
        const centerY = this.#canvas.height / 2;
        const widthToDraw = img.naturalWidth * (this.#canvas.height / img.naturalHeight);
        const heightToDraw = this.#canvas.height;
        const x = centerX - widthToDraw / 2;
        const y = centerY - heightToDraw / 2;

        // 绘制当前激活的图片到canvas中央
        this.#ctx.drawImage(img, x, y, widthToDraw, heightToDraw);
    }

    /**
     * 播放轮播
     */
    play() {
        /** 轮播速度，ms */
        const speed = 200;
        let activeIndex = 0;

        setInterval(() => {
            if (activeIndex + 1 < loadedImgs.length) {
                activeIndex++;
            } else {
                activeIndex = 0;
            }

            this.#drawCurrentImage(loadedImgs[activeIndex]);
        }, speed);
    }
}

const loadingTimeline = gsap.timeline();
loadingTimeline
    .eventCallback("onUpdate", () => {
        const progress = `${Math.floor(loadedProgress.value)}%`;

        // 更新进度UI
        loadingMask.style.height = progress;
        loadingProgressEl.innerText = progress;
    })
    .eventCallback("onComplete", () => {
        if (loadedProgress.value !== 100) return;

        // 给一点延迟执行，才能看到100%的进度
        setTimeout(() => {
            // 移除loading元素
            loadingEl.remove();

            // 显示页面内容
            m2LogoEl.classList.remove("hide");

            // 等待 M2INF 动画结束
            setTimeout(() => {
                const swiper = new Swiper();

                // 根据屏幕宽度动态设置动画时长
                const duration = Math.max(webgl.clientWidth, 700);

                // 显示 WebGL 元素
                webgl.style.setProperty("--duration", duration);
                webgl.classList.add("show");

                // 等待 WebGL 元素展开动画结束
                setTimeout(() => {
                    // 触发波浪
                    deltas.max = 2;

                    // 播放轮播
                    swiper.play();
                }, duration / 2);
            }, 1800);
        }, 400);
    });

/**
 * 更新进度条的方法
 */
const updateProgress = () => {
    loadedCount++;

    const newValue = (loadedCount / totalCount) * 100;

    loadingTimeline.to(loadedProgress, { value: newValue, duration: 0.15 }, `${newValue}%`);
};

// 预加载图片并更新进度
for (let i = 0; i < totalCount; i++) {
    const img = new Image();

    img.onload = function () {
        loadedImgs.push(img);

        // 更新加载进度
        updateProgress();
    };

    img.src = imageUrls[i];
}
